import React from 'react'
import { Flex, Text } from '@primer/components'
import { StaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import theme from '../../config/theme'

const SubtleLink = styled.a`
  color: ${theme.colors.nav.color};
  width: 100%;

  :hover,
  :hover svg {
    color: ${theme.colors.nav.colorHover};
  }
`

const SubtleIcon = styled(Text)`
  color: ${theme.colors.nav.iconColor};
  transition: color 0.1s ease 0s;
`

const Icon = icon => (
  <SubtleIcon
    as={FontAwesomeIcon}
    icon={['fab', icon.icon]}
    fontSize={1}
    fixedWidth
    mr={2}
  />
)

const SocialLink = ({ name, url, icon, menuVisible }) => {
  return (
    <Text
      as={SubtleLink}
      px={5}
      py={2}
      width={1}
      fontSize={1}
      activeStyle={{ color: theme.colors.nav.activeColor }}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={menuVisible ? null : -1}
    >
      <Icon icon={icon} />
      {name}
    </Text>
  )
}

const MenuSocialLinks = props => (
  <StaticQuery
    query={graphql`
      query MenuSocialLinksQuery {
        allLinksYaml {
          nodes {
            name
            url
            icon
            menuOrder
          }
        }
      }
    `}
    render={data => {
      const sortedLinks = data.allLinksYaml.nodes
        .filter(link => link.menuOrder !== null)
        .sort((a, b) => a.menuOrder - b.menuOrder)

      return sortedLinks.map((link, i) => (
        <Flex alignItems="center" key={'linkwrapper' + i}>
          <SocialLink
            name={link.name}
            url={link.url}
            icon={link.icon}
            key={link.name + link.url + i}
            menuVisible={props.menuVisible}
          />
        </Flex>
      ))
    }}
  />
)

export default MenuSocialLinks
