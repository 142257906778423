import React from 'react'
import { themeGet, Box, Text, Flex } from '@primer/components'
import { Link as RouterLink } from 'gatsby'
import styled from 'styled-components'

import LogoOutlineLight from '../../images/svgs/logo-outline-light.svg'
import LogoSolidLight from '../../images/svgs/logo-solid-light.svg'

const LogoLink = styled(Box)`
  & span {
    letter-spacing: 0.05em;
  }

  &:hover .logo-outline {
    opacity: 0;
  }

  &:hover .logo-solid {
    opacity: 1;
  }
`

const LogoWrapper = styled(Flex)`
  height: ${themeGet('space.6')};
`

const LogoSvg = styled(Box)`
  height: ${themeGet('space.5')};
  width: auto;
  opacity: 1;
  transition: opacity ease 100ms;
`

const LogoSvgOverlay = styled(Box)`
  height: ${themeGet('space.5')};
  width: auto;
  opacity: 0;
  transition: opacity ease 100ms;
  
  position: absolute;
`

const Logo = ({ ...rest }) => (
  <LogoLink
    as={RouterLink}
    to="/"
    display="block"
    py={3}
    px={3}
    title="Acknosyn — Home"
    aria-label="Home page"
    {...rest}
  >
    <LogoWrapper alignItems="center">
      <LogoSvg className="logo-outline" as={LogoOutlineLight} mr={1} />
      <LogoSvgOverlay as={LogoSolidLight} className="logo-solid" mr={1} />
      <Text
        className="logo-text"
        fontSize="2"
        color="bodytext"
        fontWeight="bold"
        ml={2}
      >
        Acknosyn
      </Text>
    </LogoWrapper>
  </LogoLink>
)

export default Logo
