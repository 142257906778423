export const faShazam = {
  prefix: 'fab',
  iconName: 'shazam',
  icon: [
    162,
    162,
    [],
    '',
    'M81,0C36.27,0,0,36.26,0,81c0,44.73,36.27,81,81,81c44.73,0,81-36.27,81-81C162,36.26,125.74,0,81,0zM67.68,112.84c-8.67,0-17.32-2.87-23.96-8.61c-8.17-7.06-13.01-16.67-13.65-27.05c-0.6-10.08,2.82-19.8,9.66-27.35c7.68-8.49,21.46-21.68,22.04-22.23c3.74-3.58,9.65-3.44,13.23,0.3c3.57,3.74,3.44,9.67-0.3,13.24c-0.14,0.13-13.87,13.28-21.11,21.27c-3.42,3.79-5.14,8.63-4.84,13.63c0.33,5.31,2.88,10.28,7.2,14.02c5.68,4.92,16.89,5.99,23.91-0.29c4.17-3.75,9.24-9.26,9.29-9.31c3.5-3.81,9.42-4.06,13.22-0.55c3.8,3.5,4.06,9.43,0.56,13.24c-0.23,0.24-5.73,6.21-10.58,10.57C85.59,109.79,76.63,112.84,67.68,112.84z M124.28,112.18c-7.69,8.48-21.46,21.67-22.05,22.23c-1.81,1.73-4.14,2.59-6.46,2.59c-2.48,0-4.93-0.97-6.77-2.89c-3.58-3.75-3.44-9.67,0.3-13.24c0.14-0.14,13.88-13.29,21.11-21.28c3.42-3.78,5.14-8.62,4.84-13.63c-0.32-5.3-2.88-10.28-7.2-14.01c-5.68-4.91-16.89-6-23.9,0.3c-4.18,3.74-9.24,9.25-9.28,9.3c-3.51,3.8-9.42,4.05-13.23,0.56c-3.8-3.5-4.06-9.43-0.56-13.24c0.23-0.26,5.72-6.23,10.58-10.58c13.32-11.95,35.13-12.19,48.63-0.52c8.17,7.06,13.02,16.67,13.65,27.06C134.55,94.9,131.11,104.62,124.28,112.18z',
  ],
}
