import React from 'react'
import { Link } from 'gatsby'
import { Text } from '@primer/components'
import styled from 'styled-components'

import theme from '../../config/theme'

const ColouredLink = styled(Link)`
  &:hover {
    color: ${theme.colors.nav.colorHover};
  }
`

const FooterLink = ({ page, url, ...rest }) => {
  return (
    <Text
      as={ColouredLink}
      to={url}
      fontSize={2}
      color="nav.colorFooter"
      px={4}
      py={2}
      {...rest}
    >
      {page}
    </Text>
  )
}

export default FooterLink
