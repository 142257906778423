export const faTidal = {
  prefix: 'fab',
  iconName: 'tidal',
  icon: [
    176,
    176,
    [],
    '',
    'M-0.489,59.215L28.787,88.42l29.276-29.205L28.787,30.011L-0.489,59.215z M58.224,59.215L87.5,88.42l29.276-29.205L87.5,30.011L58.224,59.215z M146.213,30.011l-29.276,29.205l29.276,29.205l29.276-29.205L146.213,30.011z M58.224,117.784L87.5,146.989l29.276-29.205L87.5,88.58L58.224,117.784z',
  ],
}
