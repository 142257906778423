export const faDeezer = {
  prefix: 'fab',
  iconName: 'deezer',
  icon: [
    147,
    147,
    [],
    '',
    'M0,106.5h26.8v8.1H0V106.5z M0,95.9h26.8v8.1H0V95.9z M0,85.4h26.8v8.1H0V85.4z M0,74.8h26.8v8.1H0V74.8z M0,64.2h26.8v8.1 H0V64.2z M30.1,106.5h26.8v8.1H30.1V106.5z M30.1,95.9h26.8v8.1H30.1V95.9z M30.1,85.4h26.8v8.1H30.1V85.4z M60.2,106.5H87v8.1H60.2 V106.5z M60.2,95.9H87v8.1H60.2V95.9z M60.2,85.4H87v8.1H60.2V85.4z M60.2,74.8H87v8.1H60.2V74.8z M60.2,64.2H87v8.1H60.2V64.2z M60.2,53.7H87v8.1H60.2V53.7z M60.2,43.1H87v8.1H60.2V43.1z M90.2,106.5h26.8v8.1H90.2V106.5z M90.2,95.9h26.8v8.1H90.2V95.9z M90.2,85.4h26.8v8.1H90.2V85.4z M90.2,74.8h26.8v8.1H90.2V74.8z M90.2,64.2h26.8v8.1H90.2V64.2z M120.3,106.5h26.8v8.1h-26.8 C120.3,114.6,120.3,106.5,120.3,106.5z M120.3,95.9h26.8v8.1h-26.8C120.3,104.1,120.3,95.9,120.3,95.9z M120.3,85.4h26.8v8.1h-26.8 C120.3,93.5,120.3,85.4,120.3,85.4z M120.3,74.8h26.8v8.1h-26.8C120.3,82.9,120.3,74.8,120.3,74.8z M120.3,64.2h26.8v8.1h-26.8 C120.3,72.4,120.3,64.2,120.3,64.2z M120.3,53.7h26.8v8.1h-26.8C120.3,61.8,120.3,53.7,120.3,53.7z M120.3,43.1h26.8v8.1h-26.8 C120.3,51.2,120.3,43.1,120.3,43.1z M120.3,32.5h26.8v8.1h-26.8C120.3,40.7,120.3,32.5,120.3,32.5z',
  ],
}
