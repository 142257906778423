import React from 'react'
import { themeGet, Flex, Text, Box, Link } from '@primer/components'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Navigation from '@material-ui/icons/Navigation'

import PageSection from './PageSection'
import FooterLink from './FooterLink'
import Logo from './Logo'

const NoUnderlineLink = styled(Link)`
  :hover {
    text-decoration: none !important;
    color: ${themeGet('colors.primary')};
  }
`

const HoverText = styled(Text)`
  transition: all ease 200ms;
  opacity: 1;

  @media (min-width: ${themeGet('breakpoints.1')}) {
    opacity: 0;
  }

  a:hover & {
    opacity: 1;
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterLinksQuery {
          allPagesYaml {
            nodes {
              name
              url
              order
              spacer
              hide
              devOnly
            }
          }
        }
      `}
      render={data => {
        const sortedLinks = data.allPagesYaml.nodes
          .filter(node => !node.hide)
          .filter(
            node => !(node.devOnly && process.env.NODE_ENV !== 'development')
          )
          .sort((a, b) => a.order - b.order)

        return (
          <PageSection
            as="footer"
            py={[0, 6, 8, 8]}
            px={[0, 4, 8, 8]}
            bg="accent.0"
          >
            <Flex flexDirection="column">
              <Flex
                flexDirection={['column', 'row']}
                mt={[3, 0]}
                flexWrap="wrap"
              >
                {sortedLinks.map((link, i) =>
                  link.spacer ? (
                    <Box my={[2, 0]} key={'spacer' + i} />
                  ) : (
                    <FooterLink
                      page={link.name}
                      url={link.url}
                      key={link.name + link.url + i}
                    />
                  )
                )}
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mt={[4, 4, 4, 6]}
                pl={1}
              >
                <Logo />

                <Flex
                  as={NoUnderlineLink}
                  mr={[4, 4, 0]}
                  color="accent.2"
                  title="Back to top"
                  style={{ cursor: 'default' }}
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <Navigation style={{ transform: 'scale(1.25)' }} />
                  <HoverText mt={2}>Back to top</HoverText>
                </Flex>
              </Flex>

              <Text
                as="small"
                fontSize={1}
                color="accent.4"
                textAlign="center"
                mt={[5]}
                mb={[8, 2]}
              >
                © Acknosyn
              </Text>
            </Flex>
          </PageSection>
        )
      }}
    />
  )
}

export default Footer
