import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faSpotify,
  faApple,
  faSoundcloud,
  faInstagram,
  faGoogle,
  faFacebookF,
  faTwitter,
  faYoutube,
  faNapster,
  faAmazon,
} from '@fortawesome/free-brands-svg-icons'
import { faTidal } from './faTidal'
import { faShazam } from './faShazam'
import { faDeezer } from './faDeezer'

library.add(
  faSpotify,
  faSoundcloud,
  faApple,
  faInstagram,
  faGoogle,
  faFacebookF,
  faTwitter,
  faYoutube,
  faAmazon,
  faNapster,
  faTidal,
  faShazam,
  faDeezer
)
