import React from 'react'
import PropTypes from 'prop-types'
import { BaseStyles, Box, Flex } from '@primer/components'
import { createGlobalStyle } from 'styled-components'
import styled, { ThemeProvider } from 'styled-components'

import LogoHeader from './LogoHeader'
import Nav from './Nav'
import Footer from './Footer'

import theme from '../../config/theme'
import '../../icons/fontawesome'

const GlobalStyle = createGlobalStyle`
  body {
    background: ${theme.colors.light} !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  :focus {
    outline: ${theme.colors.blue[7]} dotted thin;
  }
`

const StretchBox = styled(Box)`
  flex: 1;
  display: flex;
`

const Layout = ({ children }) => (
  <BaseStyles>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Flex as={Box} color="bodytext" minHeight="100vh" flexDirection="column">
        <header>
          <LogoHeader />
          <Nav />
        </header>
        <StretchBox as="main">{children}</StretchBox>
        <Footer />
      </Flex>
    </ThemeProvider>
  </BaseStyles>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
