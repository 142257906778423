import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { themeGet, Box, Flex } from '@primer/components'
import styled from 'styled-components'

import MenuLink from './MenuLink'
import MenuSocialLinks from './MenuSocialLinks'

const MovedContainer = styled(Box)`
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  border-left: 1px solid ${themeGet('colors.accent.1')};
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); // easeOutQuart https://easings.net/en#easeOutQuart
  overflow: scroll;
  z-index: 1000;
`

const MenuDivider = styled(Box)`
  border-top: 1px solid ${themeGet('colors.accent.1')};
`

const hide = {
  transform: 'translate3d(200px, 0, 0)',
}
const show = {
  overflow: 'hidden',
}

const Menu = props => (
  <StaticQuery
    query={graphql`
      query MenuPageLinksQuery {
        allPagesYaml {
          nodes {
            name
            url
            order
            spacer
            hide
            devOnly
          }
        }
      }
    `}
    render={data => {
      let visibility = props.menuVisible ? show : hide

      const sortedLinks = data.allPagesYaml.nodes
        .filter(node => !node.hide)
        .filter(
          node => !(node.devOnly && process.env.NODE_ENV !== 'development')
        )
        .sort((a, b) => a.order - b.order)

      return (
        <MovedContainer
          as="nav"
          role="navigation"
          onClick={props.handleMouseDown}
          style={visibility}
          bg="light"
        >
          <Flex pt={9} mt={0} flexDirection="column">
            {sortedLinks.map((link, i) =>
              link.spacer ? (
                <Box my={2} key={'spacer' + i} />
              ) : (
                <MenuLink
                  page={link.name}
                  url={link.url}
                  key={link.name + link.url + i}
                  tabIndex={props.menuVisible ? null : -1}
                />
              )
            )}

            <MenuDivider my={[4, 7]} />

            <MenuSocialLinks menuVisible={props.menuVisible} />
          </Flex>
        </MovedContainer>
      )
    }}
  />
)

export default Menu
