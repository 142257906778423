import React from 'react'
import { Link } from 'gatsby'
import { Text } from '@primer/components'
import styled from 'styled-components'

import theme from '../../config/theme'

const ColouredLink = styled(Link)`
  &:hover {
    color: ${theme.colors.nav.colorHover};
  }
`

const MenuLink = ({ page, url, ...rest }) => {
  return (
    <Text
      as={ColouredLink}
      to={url}
      fontSize={[3, 2]}
      fontWeight="bold"
      color="nav.color"
      activeStyle={{ color: theme.colors.nav.activeColor }}
      px={5}
      py={2}
      {...rest}
    >
      {page}
    </Text>
  )
}

export default MenuLink
