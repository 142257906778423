import React from 'react'
import { themeGet, Box } from '@primer/components'
import styled from 'styled-components'

const TopBorder = styled(Box)`
  border-top: 1px solid ${themeGet('colors.border.gray')};
`

const PageSection = ({ children, ...rest }) => (
  <TopBorder as="section" width={1} {...rest}>
    {children}
  </TopBorder>
)

export default PageSection
