import React from 'react'
import { themeGet, Button, Flex } from '@primer/components'
import styled from 'styled-components'
import Menu from '@material-ui/icons/Menu'
import Close from '@material-ui/icons/Close'

const RoundButton = styled(Button)`
  border-radius: 50%;
  border: 2px solid ${themeGet('colors.bodytext')};
  box-shadow: 0 0 0 4px ${themeGet('colors.light')};
  background-color: ${themeGet('colors.bodytext')};
  background-image: none; // Overrides button default styling
  padding: 0;
  width: ${themeGet('space.6')};
  height: ${themeGet('space.6')};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  color: ${themeGet('colors.white')};
  cursor: default;
  transition: all 100ms ease, box-shadow 300ms ease;

  :hover {
    background-color: ${themeGet('colors.light')};
    background-image: none; // Overrides button default styling
    border: 2px solid ${themeGet('colors.bodytext')};
    color: ${themeGet('colors.bodytext')};
  }

  :focus {
    background-color: ${themeGet('colors.light')};
    color: ${themeGet('colors.bodytext')};
    box-shadow: 0 0 0 1px ${themeGet('colors.primary')};
    border: 1px solid ${themeGet('colors.primary')};
    transition: all 300ms ease;
    
    :hover {
      border: 2px solid ${themeGet('colors.primary')};
      box-shadow: 0 0 0 2px ${themeGet('colors.primary')};
      transition: all 200ms ease, border 100ms ease;
    }
  }
`

const MenuButton = props => (
  <RoundButton
    onClick={props.onClickAction}
    mr={4}
    mt={4}
    title="Show / Hide menu"
    aria-label="Open main menu"
  >
    <Flex justifyContent="center">
      {props.menuVisibility ? <Close /> : <Menu />}
    </Flex>
  </RoundButton>
)

export default MenuButton
