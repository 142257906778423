import React from 'react'

import Menu from './Menu'
import MenuButton from './MenuButton'

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.wrapperRef = this.wrapperRef.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('keydown', this.escFunction, false)
  }

  toggleMenu = () => {
    this.setState(prevState => {
      return { visible: !prevState.visible }
    })
  }

  wrapperRef = element => {
    this.navRef = element
  }

  handleMouseDown = e => {
    this.toggleMenu()

    e.stopPropagation()
  }

  handleClickOutside(event) {
    if (
      this.state.visible &&
      this.navRef &&
      !this.navRef.contains(event.target)
    ) {
      this.toggleMenu()
    }
  }

  escFunction(event) {
    if (this.state.visible && event.keyCode === 27) {
      this.toggleMenu()
    }
  }

  render() {
    return (
      <div ref={this.wrapperRef}>
        <MenuButton
          onClickAction={this.handleMouseDown}
          menuVisibility={this.state.visible}
        />
        <Menu
          handleMouseDown={this.handleMouseDown}
          menuVisible={this.state.visible}
        />
      </div>
    )
  }
}

export default Nav
