import React from 'react'
import { Absolute } from '@primer/components'

import Logo from './Logo'

const LogoHeader = () => (
  <Absolute left={0} top={0} ml={[0, 3]} mt={2} style={{ zIndex: 1000 }}>
    <Logo px={4} />
  </Absolute>
)

export default LogoHeader
