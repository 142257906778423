import { theme as primer } from '@primer/components'

const light = 'white'
// const canvas = '#ebe8d8'
// const canvasLighter = '#f1f2eb'

const primary = '#2424ff'
const secondary = '#8821f5'

const accent1 = '#FAFAFA'
const accent2 = '#EFEFEF'
const accent3 = '#999999'
const accent4 = '#888888'
const accent5 = '#666666'
const accent6 = '#444444'
const accent7 = '#333333'
const accent8 = '#111111'

const iconColor = '#E4E4E4'

const themeOverrides = {
  colors: {
    ...primer.colors,
  },
  fontWeights: {
    ...primer.fontWeights,
    bold: 700,
  },
  radii: [...primer.radii, '9px'],
}

const customTheme = {
  colors: {
    ...themeOverrides.colors,
    primary: primary,
    secondary: secondary,
    dark: primer.colors.black,
    light,
    accent: [
      accent1,
      accent2,
      accent3,
      accent4,
      accent5,
      accent6,
      accent7,
      accent8,
    ],
    nav: {
      color: primer.colors.bodytext,
      colorFooter: accent5,
      colorHover: primary,
      activeColor: primary,
      iconColor,
    },
  },
}

const theme = Object.assign({}, primer, themeOverrides, customTheme)

export default theme
